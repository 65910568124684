<template>
  <div class="trash-organizations">
    <!-- La barre d'action -->
    <PageHeader
      v-model="query"
      title="Cabinets supprimées"
      :has-actions="smallActionButtons.length > 0"
      :has-search="true"
    >
      <md-button
        v-for="(button, index) in smallActionButtons"
        :key="index"
        class="md-icon-button md-icon-button--square md-dense md-raised md-primary--inverted-outline"
        @click="onSmallButtonClick(button.actionName)"
      >
        <md-icon class="md-primary--inverted">
          {{ button.icon }}
        </md-icon>

        <md-tooltip md-direction="bottom">
          {{ button.label }}
        </md-tooltip>
      </md-button>
    </PageHeader>

    <!-- La liste des entreprises -->
    <sortable-list
      default-sort="name"
      selectable="multiple"
      :items="filter"
      :parameters="listParameters"
      @selected="onSelect"
    />

    <!-- Pop-up de confirmation de suppression -->
    <confirm-action-modal
      v-if="isConfirmDeleteModalOpen"
      :text="confirmDeleteModalText"
      :object-to-act-upon="selectedList"
      @close="closeConfirmDeleteModal"
      @confirm="onDelete"
    />
  </div>
</template>

<script>
import organizationsModel from "../../../model/organizations";

import confirmActionModal from "../../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";

export default {
  name: "TrashOrganization",

  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
  },
  data() {
    return {
      isDebug: false,
      query: "",
      organizations: [],
      selectedList: [],
      listParameters: [
        {
          name: "Nom",
          key: "name",
        },
        {
          name: "SIREN",
          key: "siren",
        },
        {
          name: "Numéro de téléphone",
          key: "tel",
        },
      ],

      isConfirmDeleteModalOpen: false,

      /* Texte du modal de confirmation de suppression */
      confirmDeleteModalText: {
        header: "Suppression",
        body(object) {
          return (
            "Vous êtes sur le point de supprimer définitivement <b>" +
            object.length +
            " élément" +
            (object.length > 1 ? "s.</b>" : ".</b>")
          );
        },
        question: "Êtes vous sûr de vouloir le faire ?",
      },
    };
  },

  computed: {
    hasSelection() {
      return this.selectedList.length > 0;
    },

    filter() {
      if (this.query === "") {
        return this.organizations;
      } else {
        let q = this.query.toLowerCase();
        return this.organizations.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(q) > -1 ||
            item.siren.toLowerCase().indexOf(q) > -1 ||
            item.tel.toLowerCase().indexOf(q) > -1
          );
        });
      }
    },

    smallActionButtons() {
      if (this.hasSelection) {
        return [
          /* Supprimer définitivement */
          {
            actionName: "delete",
            isSVG: false,
            icon: "delete_forever",
            label: "Supprimer définitivement",
          },
          /* Restaurer */
          {
            actionName: "restore",
            isSVG: false,
            icon: "publish",
            label: "Restaurer",
          },
        ];
      }

      return [];
    },
  },

  mounted() {
    organizationsModel.getTrashed().then((organizations) => {
      this.organizations = organizations;
    });
  },

  methods: {
    goBack() {
      window.history.back();
    },
    onSelect(items) {
      this.selectedList = items;
    },

    onSmallButtonClick(actionName) {
      if (actionName === "delete") {
        this.openConfirmDeleteModal();
      } else {
        this.onRestore();
      }
    },

    onDelete() {
      this.selectedList.forEach((item) => {
        organizationsModel
          .remove(item.id)
          .then(() => {
            this.organizations = this.organizations.filter((i) => {
              return i.id !== item.id;
            });

            this.$toasted.global.AppSucces({
              message: "Le cabinets " + item.name + " à bien été supprimée",
            });
          })
          .catch((err) => {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          });
      });
    },
    onRestore() {
      this.selectedList.forEach((item) => {
        organizationsModel
          .restore(item.id)
          .then(() => {
            this.organizations = this.organizations.filter((i) => {
              return i.id !== item.id;
            });

            this.$toasted.global.AppSucces({
              message: "Le cabinets " + item.name + " à bien été restaurée",
            });
          })
          .catch((err) => {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          });
      });
    },
    openConfirmDeleteModal() {
      console.log("Open modal");
      this.isConfirmDeleteModalOpen = true;
    },

    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.trash-organizations {
  .debug-log {
    max-height: 200px;
    overflow-y: scroll;
  }
}
</style>
